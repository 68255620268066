<template>
  <AppHeader />
  <SubHeader />
  <SideBar />
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import SubHeader from './components/SubHeader.vue'
import SideBar from './components/SideBar.vue'
//import { RouterView } from 'vue-router'

export default {
  name: 'App',
  components: {
    AppHeader,
    SubHeader,
    SideBar
  }
}
</script>

<style>
#app {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
