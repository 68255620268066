<template>
    <div class="dropdown-item" @click="changeLanguage">
        <img :src="lang.flag" alt="flag" style="height: 20px;">
        {{ lang.name }}
    </div>
</template>

<script>
export default {
    props: {
        lang: Object
    },
    methods: {
        changeLanguage() {
            this.$emit('changeLanguage', this.lang.code, this.lang.flag);
        }
    }
}
</script>