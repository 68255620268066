<template>
    <section 
        :class="['sideBar', { 'sidebar-open': isOpen || isHovered }]" 
        @mouseenter="isHovered = true" 
        @mouseleave="isHovered = false">
        <div class="content">
            <ul class="menu-container">
                <li>
                    <div class="item-menu" @click="toggleSidebar">
                        <div class="content-menu" :style="{ 'justify-content': isOpen || isHovered ? 'flex-start' : 'center' }">
                            <div class="icon-container">
                                <font-awesome-icon :icon="isOpen ? 'fa-xmark' : 'fa-bars'" />
                            </div>
                            <p :class="['sidebar-text', { 'sidebar-text-open': isOpen }]">{{ $t('sidebar_close_menu') }}</p>
                            <p :class="['sidebar-text', { 'sidebar-text-open': isHovered && !isOpen }]">{{ $t('sidebar_open_menu') }}</p>
                        </div>
                    </div>
                </li>

                <li class="line-container"><div class="horizontal-line"></div></li>

                <li v-for="item in menuItems" :key="item.label">
                    <div class="item-menu">
                        <div class="content-menu" :style="{ 'justify-content': isOpen || isHovered ? 'flex-start' : 'center' }">
                            <div class="icon-container">
                                <font-awesome-icon :icon="item.icon" />
                            </div>
                            <p :class="['sidebar-text', { 'sidebar-text-open': isOpen || isHovered }]">{{ item.label }}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
    name: 'SideBar',
    setup() {
        const isOpen = ref(false);
        const isHovered = ref(false);
        const { locale } = useI18n();

        const toggleSidebar = () => {
            isOpen.value = !isOpen.value;
        };

        const menuItems = [
            { icon: 'fa-house fa-fw', label: 'Home' },
            { icon: 'fa-bell', label: 'Notificações' },
            { icon: 'fa-building', label: 'Empresa' },
            { icon: 'fa-box', label: 'Produtos' },
            { icon: 'fa-user', label: 'Usuários' },
            { icon: 'fa-receipt', label: 'Pedidos' },
            { icon: 'fa-tv', label: 'Monitoramento' },
            { icon: 'fa-layer-group', label: 'Aplicativos'}
        ];

        return { 
            isOpen,
            isHovered,
            currentLanguage: locale,
            toggleSidebar,
            menuItems
        };
    },
}
</script>

<style scoped>
.sideBar {
    height: 100vh;
    background-color: #F0F0F0;
    z-index: 1;
    position: fixed;
    overflow: hidden;
    top: 10vh;
    left: 0;
    width: 3vw;
    color: white;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.2);
}

.sidebar-open {
    width: 10vw;
    align-items: flex-start !important; 
}

.sidebar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 15px;
    width: 100%;
}

.sidebar-text {
    padding: 5px 0;
    color: #4F4F4F;
    display: none;
    font-weight: 300;
    font-size: clamp(0.8rem, 0.9vw, 1.1rem);
    text-wrap: nowrap;
}

.sidebar-text-open {
    display: block !important;
}

.icon-container svg {
    color: #4F4F4F;
    flex-shrink: 0;
    font-size: 24px; 
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 20px;
    width: 100%;
}

.menu-container {
    list-style-type: none;
    width: 100%;
}

.item-menu {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 8px 8px 8px 0;
    cursor: pointer;
}

.content-menu {
    display: flex;
    align-items: center;
    padding-left: 5px;
    gap: 5px;
    flex: 1;
}

.icon-container {
    height: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.horizontal-line {
    width: 80%;
    height: 2px;
    background-color: #d3d3d3;
    border-radius: 5px;
    align-self: center; 
}

.item-menu:hover {
    background-color: #E5E5E5;
}

.line-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media(max-width: 1024px) {
    .sideBar {
        display: none;
        align-items: center !important;
    }

    .sidebar-open {
        width: 100vw;
        height: 100vh;
    }
}
</style>