<template>
    <div class="dropdown-item" >
        {{ label }} <font-awesome-icon :icon="`fa-solid ${icon}`"/>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        icon: String
    }
}
</script>

<style scoped>
    .dropdown-item svg {
        margin-left: auto;
        padding-left: 10px;
    }
</style>