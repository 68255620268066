import { createI18n } from 'vue-i18n';

const messages = {
  "EN": {
    loading: "Loading...",
    select: "Select",
    subheader_store: "Store",
    subheader_company: "Company",
    subheader_search: "Search companies...",
    subheader_search_company_error: "Error searching companies",
    subheader_search_store_error: "Error searching stores",
    subheader_empty_company: "No companies found",
    subheader_empty_store: "No stores found",
    header_leave: "Leave",
    header_notification: "Notification",
    header_language: "Language",
    header_profile: "Profile",
    sidebar_open_menu: "Open menu",
    sidebar_close_menu: "Close menu"
  },
  "PT-BR": {
    loading: "Carregando...",
    select: "Selecione",
    subheader_store: "Loja",
    subheader_company: "Empresa",
    subheader_search: "Pesquisar empresas...",
    subheader_search_company_error: "Erro ao buscar empresas",
    subheader_search_store_error: "Erro ao buscar lojas",
    subheader_empty_company: "Nenhuma empresa encontrada",
    subheader_empty_store: "Nenhuma loja encontrada",
    header_leave: "Sair",
    header_notification: "Notificação",
    header_language: "Idioma",
    header_profile: "Perfil",
    sidebar_open_menu: "Expandir Menu",
    sidebar_close_menu: "Fechar Menu",
  },
  "ES": {
    loading: "Cargando...",
    select: "Seleccionar",
    subheader_store: "Tienda",
    subheader_company: "Compañía",
    subheader_search: "Buscar compañías...",
    subheader_search_company_error: "Error al buscar compañías",
    subheader_search_store_error: "Error al buscar tiendas",
    subheader_empty_company: "No se encontraron compañías",
    subheader_empty_store: "No se encontraron tiendas",
    header_leave: "Salir",
    header_notification: "Notificación",
    header_language: "Idioma",
    header_profile: "Perfil",
    sidebar_open_menu: "Abrir menú",
    sidebar_close_menu: "Cerrar menú"
  },
};

const i18n = createI18n({
  locale: 'PT-BR', 
  fallbackLocale: 'EN', 
  messages,
});

export default i18n;