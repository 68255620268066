<template>
    <section class="subHeader">
        <div class="content">

            <div class="info-group-company" @click="toggleDropdown('dropdownEnterpriseOpen')">
                <b>{{ $t('subheader_company') }}:</b>
                <span v-if="loading" class="text-nowrap">{{ $t('loading') }}</span>
                <span v-else-if="error" class="text-nowrap">{{ $t('subheader_search_company_error') }}</span>
                <span v-else-if="activeEnterprise" class="text-nowrap">
                    {{ activeEnterprise.display_name }}
                </span>
                <span class="text-nowrap" v-else>{{ $t('select') }}</span>

                <font-awesome-icon icon="fa-caret-down" />

                <div v-if="dropdownStates.dropdownEnterpriseOpen.value" class="dropdown-company">

                    <input 
                        type="text" 
                        v-model="searchQuery" 
                        :placeholder="$t('subheader_search')"
                        class="dropdown-search"
                        @click.stop
                    />

                    <div class="horizontal-line"></div>

                    <div v-if="filteredEnterprises.length > 0" >
                        <div v-for="(enterprise, index) in filteredEnterprises" :key="index" @click="toggleDropdown('dropdownEnterpriseOpen')">
                            <div v-if="index != 0" class="horizontal-line"></div>
                            <p class="dropdown-item-company" @click="selectEnterprise(enterprise)">
                                {{ enterprise.display_name }}
                            </p>
                        </div>
                    </div>  
                    <div v-else>
                        <p class="dropdown-item-company">{{ $t('subheader_empty_company') }}</p>
                    </div>
                </div>

            </div>

            <div class="vertical-line"></div>

            <div class="info-group-company" @click="toggleDropdown('dropdownStoreOpen')">
                <b>{{ $t('subheader_store') }}:</b>
                <span v-if="loading" class="text-nowrap">{{ $t('loading') }}</span>
                <span v-else-if="error" class="text-nowrap">{{ $t('subheader_search_store_error') }}</span>
                <span v-else-if="activeStore" class="text-nowrap">
                    {{ activeStore.store_name }}
                </span>
                <span class="text-nowrap" v-else>{{ $t('select') }}</span>

                <font-awesome-icon icon="fa-caret-down" />

                <div v-if="dropdownStates.dropdownStoreOpen.value" class="dropdown-company" @click="toggleDropdown('dropdownStoreOpen')">
                    <div class="horizontal-line"></div>

                    {{ stores.lenght }}

                    <div v-if="stores" >
                        <div v-for="(store, index) in stores" :key="index">
                            <div v-if="index != 0" class="horizontal-line"></div>
                            <p class="dropdown-item-company" @click="selectStore(store)">
                                {{ store.store_name }}
                            </p>
                        </div>
                    </div>
                    <div v-else>
                        <p class="dropdown-item-company">{{ $t('subheader_empty_store') }}</p>
                    </div>
                    
                </div>  

            </div>
        </div>

        <div class="language" @click="toggleDropdown('dropdownOpen')">
            <img v-if="flag" :src="flag" alt="flag" class="flag-icon">
            <p class="language-text">{{ currentLanguage }}</p>
            <font-awesome-icon icon="fa-caret-down" />

            <div v-if="dropdownStates.dropdownOpen.value" ref="dropdown" class="dropdown" @click.self="closeDropdown">
                <div 
                    v-for="lang in availableLanguages" 
                    :key="lang.code" 
                    @click.stop="changeLanguage(lang.code, lang.flag)" 
                    class="dropdown-item">
                    <img v-if="lang.flag" :src="lang.flag" alt="flag" class="dropdown-flag">
                    {{ lang.name }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref, onMounted, computed } from 'vue';
import { useEnterpriseStore } from '@/stores/enterpriseStore';

export default {
    name: 'SubHeader',
    setup() {
        const { locale } = useI18n();
        const enterpriseStore = useEnterpriseStore();
        const loading = computed(() => enterpriseStore.loading);
        const error   = computed(() => enterpriseStore.error);
        const dataEnterprise = computed(() => enterpriseStore.dataEnterprise); 

        enterpriseStore.fetchDataEnterprise();

        const activeEnterprise = computed(() => dataEnterprise.value.length > 0 ? dataEnterprise.value[0] : null);
        const otherEnterprises = computed(() => dataEnterprise.value.length > 1 ? dataEnterprise.value.slice(1) : []);

        const activeStore = computed(() => {
            if (dataEnterprise.value.length > 0 && dataEnterprise.value[0] && dataEnterprise.value[0].stores) {
                return dataEnterprise.value[0].stores.length > 0 ? dataEnterprise.value[0].stores[0] : null;
            }
            return null;
        });

        const stores = computed(() => {
            if (activeEnterprise.value && activeEnterprise.value.stores) {
                return activeEnterprise.value.stores; 
            }
            return null;
        });

        const availableLanguages = [
            { code: 'PT-BR', name: 'Português Brasileiro (PT-BR)', flag: require('../assets/flags/brasil-flag.svg') },
            { code: 'EN', name: 'English (EN)', flag: require('../assets/flags/united-states-flag.svg') },
            { code: 'ES', name: 'Español (ES)', flag: require('../assets/flags/spain-flag.svg') }
        ];

        const flag = ref(require('../assets/flags/brasil-flag.svg'));
        const dropdownStates = {
            dropdownEnterpriseOpen: ref(false),
            dropdownOpen: ref(false),
            dropdownStoreOpen: ref(false)
        };
        const searchQuery = ref('');

        const toggleDropdown = (dropdown) => {
            if (dropdownStates[dropdown] !== undefined) {
                dropdownStates[dropdown].value = !dropdownStates[dropdown].value;
            }
        };

        const changeLanguage = (lang, flagSelected) => {
            locale.value = lang;
            flag.value = flagSelected;
            dropdownStates.dropdownOpen.value = false; 
            localStorage.setItem('language', lang);
            localStorage.setItem('flag', flagSelected);
        };

        const selectEnterprise = (enterprise) => {
            enterpriseStore.dataEnterprise = [
                enterprise,
                ...enterpriseStore.dataEnterprise.filter(e => e !== enterprise)
            ];

            dropdownStates.dropdownEnterpriseOpen.value = false; 
        };

        const selectStore = (store) => {
            console.log(enterpriseStore.dataEnterprise[0].stores);
            enterpriseStore.dataEnterprise[0].stores = [
                store,
                ...enterpriseStore.dataEnterprise[0].stores.filter(e => e !== store)
            ];

            dropdownStates.dropdownStoreOpen.value = false; 
        };

        onMounted(() => {
            const savedLanguage = localStorage.getItem('language');
            const savedFlag = localStorage.getItem('flag');
            if (savedLanguage) locale.value = savedLanguage;
            if (savedFlag) flag.value = savedFlag;
        });

        const filteredEnterprises = computed(() => {
            return otherEnterprises.value.filter((enterprise) => 
                enterprise.display_name.toLowerCase().includes(searchQuery.value.toLowerCase())
            );
        });

        return {
            currentLanguage: locale,
            flag,
            availableLanguages,
            dropdownStates,
            activeEnterprise, 
            otherEnterprises,
            filteredEnterprises,
            searchQuery,
            stores,
            activeStore,
            dataEnterprise,
            loading,
            error,   
            toggleDropdown,
            changeLanguage,
            selectEnterprise,
            selectStore
        };
    }
}
</script>

<style scoped>
.subHeader {
    background-color: #F0F0F0;
    height: 3vh;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    z-index: 2;
    position: relative;
}

.content {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    justify-content: center;
    height: 3vh;
}

.info-group {
    display: flex;
    gap: 5px;
    align-items: center;
}

.info-group-company {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.vertical-line {
    width: 2px;
    height: 80%;
    background-color: black;
    margin: 0 15px;
    border-radius: 5px;
}

.language {
    margin-left: auto;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
}

.flag-icon {
    height: 80%;
}

.language-text {
    font-weight: 500;
}

.dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-top: 5px;
    z-index: 10;
    padding: 10px 10px;
}

.dropdown-company {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-top: 5px;
    z-index: 10;
    width: 100%;
}

.dropdown-item {
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    text-wrap: nowrap;
}

.dropdown-item-company {
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    text-wrap: nowrap;
    font-weight: 300;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(1rem, 0.05vw + 0.10rem, 3rem);
}

.dropdown-item-company:hover {
    background-color: #f0f0f0;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

.dropdown-flag {
    height: 20px;
}

.text-nowrap {
    color: #828282;
    white-space: nowrap;
}

.horizontal-line {
    width: 100%;
    height: 2px;
    background-color: #d3d3d3;
    border-radius: 5px;
    align-self: center; 
}

.dropdown-search {
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border: none;
    font-size: clamp(1rem, 0.05vw + 0.10rem, 3rem);
}

.dropdown-item-company:hover {
    background-color: #f0f0f0;
}

@media (max-width: 768px) {
    .language {
        display: none;
    }

    .text-nowrap {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
    }
}

@media (max-width: 375px) {
    .text-nowrap {
        max-width: 60px;
    }
}

@media (min-width: 768px) {
    .dropdown-company {
        width: 250px; 
    }
}
</style>