<template>
    <header>
        <div class="logo-container">
            <img class="logo-linkhub" src="../assets/logo-linkhub-new.png" alt="Logo">
        </div>
        <div class="header-controls">
            <div class="bell-menu">
                <font-awesome-icon icon="fa-regular fa-bell" size="xl" class="bell-icon"/>
                <span class="bell-number">0</span>
            </div>

            <div class="vertical-line"></div>

            <div class="client-menu">
                <div class="client-name-div" @click="toggleDropdown('dropdownOpen')">
                    <p class="client-name">Felipe Silva</p>
                    <font-awesome-icon icon="fa-solid fa-angle-down" size="sm" class="dropdown-icon"/>
                    
                    <div v-if="dropdownOpen" class="dropdown" ref="dropdown">
                        <DropdownItem :label="$t('header_profile')" icon="fa-user"/>
                        <DropdownItem :label="$t('header_notification')" icon="fa-bell" class="dropdown-mobile"/>
                        
                        <div @click.stop="toggleDropdown('dropdownOpenLang')">
                            <DropdownItem :label="$t('header_language')" icon="fa-angle-down"/>
                            
                            <div v-if="dropdownOpenLang" class="dropdown">
                                <DropdownLang 
                                    v-for="lang in availableLanguages" 
                                    :key="lang.code" 
                                    :lang="lang" 
                                    @changeLanguage="changeLanguage" />
                            </div>
                        </div>
                        <hr>
                        <DropdownItem :label="$t('header_leave')" icon="fa-sign-out-alt"/>
                    </div>
                </div>
                <font-awesome-icon icon="fa-user-circle" class="user-icon"/>
            </div>
        </div>
    </header>
</template>


<script>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import DropdownItem from './Dropdown/DropdownItem.vue';
import DropdownLang from './Dropdown/DropdownLang.vue';

export default {
    name: 'AppHeader',
    components: { DropdownItem, DropdownLang },
    setup() {
        const { locale } = useI18n();

        const availableLanguages = [
            { code: 'PT-BR', name: 'Português Brasileiro (PT-BR)', flag: require('../assets/flags/brasil-flag.svg') },
            { code: 'EN', name: 'English (EN)', flag: require('../assets/flags/united-states-flag.svg') },
            { code: 'ES', name: 'Español (ES)', flag: require('../assets/flags/spain-flag.svg')  }
        ];

        const dropdownOpen = ref(false);
        const dropdownOpenLang = ref(false);

        const toggleDropdown = (dropdown) => {
            dropdown === 'dropdownOpen' 
                ? dropdownOpen.value = !dropdownOpen.value 
                : dropdownOpenLang.value = !dropdownOpenLang.value;
        };

        const changeLanguage = (langCode, flag) => {
            locale.value = langCode;
            dropdownOpenLang.value = false;
            localStorage.setItem('language', langCode);
            localStorage.setItem('flag', flag);
        };

        return {
            dropdownOpen,
            dropdownOpenLang,
            availableLanguages,
            toggleDropdown,
            changeLanguage
        };
    }
}
</script>

<style scoped>
    .logo-container {
        height: 80%;
    }

    .header-controls {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .bell-menu {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .bell-icon {
        color: #FFB8B1;
    }

    .client-menu {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .dropdown-icon {
        color: #FFB8B1;
    }

    .user-icon {
        color: #FFB8B1;
        font-size: 2.5em;
    }

    header {
        background-color: #2F3033;
        height: 7vh;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.5%;
        z-index: 3 !important;
        position: relative;
    }

    .logo-linkhub {
        height: 100%;
    }

    .vertical-line {
        width: 2px;           
        height: 70%;        
        background-color: white; 
        margin: 0 15px;
        border-radius: 5px;
    }

    .bell-number {
        color: #FFB8B1;
        font-size: clamp(1rem, 0.25vw + 0.80rem, 3rem);
    }

    .flex-align-center {
        display: flex; align-items: center;
    }

    .client-name {
        font-size: clamp(1rem, 0.25vw + 0.80rem, 3rem);
        font-weight: 300;
        color: white;
        line-height: 14px;
        text-wrap: nowrap;
    }

    .client-name-div {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        margin-top: 5px;
        z-index: 3;
        padding: 10px 10px;
    }

    .dropdown-item {
        padding: 5px 10px;
        cursor: pointer;
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        gap: 5px;
        text-align: start;
    }

    .dropdown-item:hover {
        background-color: #f0f0f0;
    }

    .dropdown-mobile {
        display: none;
    }

    @media(max-width: 768px) {        
        .client-menu {
            gap: 5px;
        }

        .bell-menu {
            display: none;
        }

        .vertical-line {
            display: none;
        }

        .dropdown-mobile {
            display: block;
        }
        
    }
</style>