import { defineStore } from 'pinia';
import api from '../api';

export const useEnterpriseStore = defineStore('enterpriseStore', {
    state: () => ({
        dataEnterprise: [], 
        loading: false, 
        error: null, 
    }),

    actions: {
        async fetchDataEnterprise() {
            this.loading = true;
            this.error = null;

            try {
                const response = await api.get('enterprise/list-company-store');
                this.dataEnterprise = response.data;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }

        },

        startPeriodicFetch(interval = 300000) { 
            console.log('dataenterprise ', this.dataEnterprise);
            
            if (!this.dataEnterprise.length) { 
                this.fetchDataEnterprise();    
            }
            //this.fetchDataEnterprise(); 
            setInterval(() => {
                console.log('Fetching data...');
                this.fetchDataEnterprise();
            }, interval);
        }
    },
});